var config = {
   environment : "LIVE",

   getPublicServerUrl : function() {
      switch(this.environment) {
         case "LIVE":
            return "https://account.sentab.com";
         case "STAGE":
            return "https://account-" + this.environment.toLowerCase() + ".sentab.com";
         case "TEST":
            return "https://account-" + this.environment.toLowerCase() + ".sentab.com";
         case "BUILD":
            return "https://account-" + this.environment.toLowerCase() + ".sentab.com";
         default:
            return "http://localhost:8080/PublicServer";
      }
   },

   getGoogleAnalyticsId : function() {
      switch(this.environment) {
         case "LIVE":
        	return "UA-54557260-1";
         default:
            return "UA-54557260-2";
      }
   },

   supportedCurrencies : [
      {currency : "EUR", symbol : "€"},
      {currency : "USD", symbol : "$"}
   ]
};
